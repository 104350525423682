import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import {
  getAllRouteAreaCodes,
  getStorageSackIdsByRouteAreaCode,
  createAndcloseStorageSack,
  getOrdersInSack,
  clearCloseSack,
  printStorageSackSticker,
  closeAllSacks,
  clearCloseAllSacks,
} from "../../../../../actions/index.js"
import YesNoModal from "../../../../Modals/YesNoModal/index.js"
import { BlueButton } from "../../../../common/BlueButton"
import { Spinner } from "../../../../common"
import Select from "react-select"
import { getFormatedDate } from "../../../../../utils/FormatedDate"
import NotificationModal from "../../../../Modals/NotificationModal/index.js"
import "./style.scss"

const CloseOrdersSackPage = (props) => {
  const [selectedAreaCode, setSelectedAreaCode] = useState(null)
  const [selectedSackNumber, setSelectedSackNumber] = useState(null)
  const [formatedAreaCode, setFormatedAreaCode] = useState([])
  const [formatedSackNumbers, setFormatedSackNumbers] = useState([])
  const [lastClosedSack, setLastClosedSack] = useState({})
  const [showConfirmCloseAllSacks, setShowConfirmCloseAllSacks] =
    useState(false)

  useEffect(() => {
    props.getAllRouteAreaCodes()
    return () => {}
  }, [])

  useEffect(() => {
    if (props.routeAreaCodes.length > 0 && formatedAreaCode.length === 0) {
      setFormatedAreaCode(formatRouteAreaCodesObject())
    }
  }, [props.routeAreaCodes])

  useEffect(() => {
    if (selectedAreaCode) {
      props.getStorageSackIdsByRouteAreaCode(selectedAreaCode?.id)
    }
  }, [selectedAreaCode])

  useEffect(() => {
    if (selectedSackNumber && selectedAreaCode) {
      props.getOrdersInSack(selectedAreaCode.id, selectedSackNumber?.value)
    }
  }, [selectedSackNumber])

  useEffect(() => {
    if (!props.storageSackNumbers || props.storageSackNumbers.length === 0) {
      setFormatedSackNumbers([])
      setSelectedSackNumber(null)
      return
    }

    formatSackNumbersForSelect()
  }, [props.storageSackNumbers])

  useEffect(() => {
    if (props.allStorageSacksClosedSuccess) {
      const sortedStorageSacksList = [
        ...(props.allStorageSacksList || []),
      ].sort((a, b) => a.sackNumber - b.sackNumber)

      const printSequentially = async () => {
        for (const sack of sortedStorageSacksList) {
          if (sack.ordersCount > 0) {
            await props.printStorageSackSticker(sack)
          }
        }
        props.clearCloseSack()
        setShowConfirmCloseAllSacks(false)
      }
      printSequentially()
    }
  }, [props.allStorageSacksClosedSuccess])

  useEffect(() => {
    if (props.allStorageSacksClosedSuccess) {
      setShowConfirmCloseAllSacks(false)
    }
  }, [props.allStorageSacksClosedSuccess])

  useEffect(() => {
    if (props.storageSackClosedSuccess) {
      const sackData = {
        sackNumber: props.storageSack?.sackNumber,
        barcode: props.storageSack?.barcode,
        ordersCount: props.ordersInSackList?.length,
        sackClosedDate: getFormatedDate(props.storageSack?.createdDate),
        stationName: props.ordersInSackList[0]?.stationName,
        stationNumber: props.ordersInSackList[0]?.stationNumber,
        routeName: props.ordersInSackList[0]?.routeName,
        areaCodeName: selectedAreaCode?.label,
      }
      setLastClosedSack(sackData)
      props.printStorageSackSticker(sackData)
    }
  }, [props.storageSackClosedSuccess])

  const formatRouteAreaCodesObject = () => {
    return props.routeAreaCodes
      ?.sort((a, b) => a.routeAreaCodeName.localeCompare(b.routeAreaCodeName))
      .map((obj) => ({
        label:
          obj.routeAreaCodeName +
          (obj.routeAreaCodeDesc ? " - " + obj.routeAreaCodeDesc : ""),
        value: obj.id,
        id: obj.id,
      }))
  }

  const formatSackNumbersForSelect = () => {
    let result = props.storageSackNumbers
      ?.map((num) => ({
        value: num,
        label: num.toString(),
      }))
      .sort((a, b) => a.value - b.value)

    setFormatedSackNumbers(result)
  }

  const closeSack = () => {
    const orderStorageSack = {
      routeAreaCodeId: selectedAreaCode?.id,
      sackNumber: selectedSackNumber?.value,
      stationId: props.ordersInSackList[0]?.stationId,
    }
    props.createAndcloseStorageSack(orderStorageSack)
  }

  const closeModal = () => {
    props.clearCloseSack()
    //props.getOrdersInSack(selectedAreaCode.id, selectedSackNumber?.value)
    //TODO: clear orders
  }

  const closeAllSacksModal = () => {
    props.clearCloseAllSacks()
    //TODO: clear orders
    setShowConfirmCloseAllSacks(false)
  }

  return (
    <section className="closeOrdersSackPage">
      <h2>סגירת שק הזמנות</h2>

      <div className="form-wrapper">
        <div className="form-group">
          <div className="select-wrapper">
            <Select
              options={formatedAreaCode}
              value={selectedAreaCode}
              onChange={(itm, index) => setSelectedAreaCode(itm)}
              placeholder="בחר קוד אזור"
            />
          </div>
        </div>

        <div className="form-group">
          <div className="select-wrapper">
            <Select
              isDisabled={!selectedAreaCode}
              options={formatedSackNumbers || []}
              value={selectedSackNumber}
              onChange={(itm, index) => setSelectedSackNumber(itm)}
              placeholder="בחר מספר שק"
            />
          </div>
        </div>

        <div className="button-wrapper">
          <BlueButton
            disabled={
              !selectedSackNumber || !props.ordersInSackList?.length > 0
            }
            extraClass="btn-close-sack"
            onClick={() => closeSack()}
          >
            סגור שק
          </BlueButton>

          <BlueButton
            disabled={
              !lastClosedSack || Object.keys(lastClosedSack).length === 0
            }
            extraClass="btn-print-barcode"
            onClick={() => props.printStorageSackSticker(lastClosedSack)}
          >
            הדפס ברקוד אחרון
          </BlueButton>
          <BlueButton
            extraClass="btn-refresh"
            onClick={() =>
              props.getOrdersInSack(
                selectedAreaCode.id,
                selectedSackNumber?.value
              )
            }
          >
            רענן
          </BlueButton>

          <BlueButton
            extraClass="btn-close-all"
            disabled={!selectedAreaCode}
            onClick={() => setShowConfirmCloseAllSacks(true)}
          >
            סגור כל השקים
          </BlueButton>
        </div>
      </div>
      {props.isloading ? (
        <div className="spinner">
          <Spinner zoom={0.5} />
        </div>
      ) : null}
      {props.ordersInSackList?.length > 0 ? (
        <div className="orders-list">
          <h3>רשימת הזמנות בסטטוס "נכנס לשק"</h3>
          <table className="orders-table">
            <thead>
              <tr>
                <th>מספר הזמנה</th>
                <th>מספר חבילה</th>
                <th>מספר עמדה</th>
                <th>שם עמדה</th>
              </tr>
            </thead>
            <tbody>
              {props.ordersInSackList?.map((order, index) => (
                <tr key={index}>
                  <td>{order.orderNumber}</td>
                  <td>{order.packageNumber}</td>
                  <td>{order.stationNumber}</td>
                  <td>{order.stationName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}
      <YesNoModal
        show={showConfirmCloseAllSacks}
        title={"סגירת שקים"}
        text={"האם אתה בטוח שברצונך לסגור כל השקים?"}
        onYesClick={() => props.closeAllSacks(selectedAreaCode.id)}
        onNoClick={() => setShowConfirmCloseAllSacks(false)}
      />
      <NotificationModal
        show={props.storageSackClosedSuccess}
        type="success"
        title="סגירת שק"
        text={"שק נסגר בהצלחה"}
        onOkClick={() => closeModal()}
      />
      <NotificationModal
        show={props.storageSackClosedFail}
        type="error"
        title="סגירת שק"
        text={"שק לא נסגר"}
        onOkClick={() => closeModal()}
      />
      <NotificationModal
        show={props.allStorageSacksClosedSuccess}
        type="success"
        title="סגירת שקים"
        text={"שקים נסגרו בהצלחה"}
        onOkClick={() => closeAllSacksModal()}
      />
      <NotificationModal
        show={props.allStorageSacksClosedFail}
        type="error"
        title="סגירת שקים"
        text={"תקלה בסגירת שקים"}
        onOkClick={() => closeAllSacksModal()}
      />
    </section>
  )
}

const mapStateToProps = ({ orderStorageSack, routeAreaCode }) => {
  const { routeAreaCodes } = routeAreaCode
  const {
    isloading,
    storageSackNumbers,
    ordersInSackList,
    storageSackClosedSuccess,
    storageSackClosedFail,
    storageSack,
    allStorageSacksList,
    allStorageSacksClosedSuccess,
    allStorageSacksClosedFail,
  } = orderStorageSack
  return {
    isloading,
    routeAreaCodes,
    storageSackNumbers,
    ordersInSackList,
    storageSackClosedSuccess,
    storageSackClosedFail,
    storageSack,
    allStorageSacksList,
    allStorageSacksClosedSuccess,
    allStorageSacksClosedFail,
  }
}

export default connect(mapStateToProps, {
  getAllRouteAreaCodes,
  getStorageSackIdsByRouteAreaCode,
  createAndcloseStorageSack,
  getOrdersInSack,
  clearCloseSack,
  printStorageSackSticker,
  closeAllSacks,
  clearCloseAllSacks,
})(CloseOrdersSackPage)
